::placeholder {
    font-size: 18px;
    color: #4e4f50 !important;
}

::-webkit-input-placeholder { /* Edge */
    font-size: 18px;
    color: #4e4f50 !important;
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder {
    font-size: 18px;
    color: #4e4f50 !important;
}

label{
    font-size: 17px !important;
}
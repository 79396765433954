.temp-modal {
    right: auto;
    left: auto;
    height: 640px;
    margin-top: 60px !important;
}

 
.modal-container{
    margin: auto !important;
    left: 0 !important;
    right: 0;
 
    max-height: 470px !important;
    bottom: 0px !important; 
}  

 
.modal-content{
    min-width: 2000px !important;;
}


.temp-modal h2{
 text-align: center;
}
.imgColumn{

    width: 46% !important;
} 
.four.wide.column {
    width: 16% !important;
 }

.email-confirm-icon{
    align-content: center !important;
    size: 98em !important;
}

/* .ui.column.grid > [class*="four wide"].column, .ui.grid > .column.row > [class*="four wide"].column, .ui.grid > .row > [class*="four wide"].column, .ui.grid > [class*="four wide"].column {
    width: 100% !important;
}   */
.badges-box{
    height: 252px;
    overflow-y: scroll;
    /* margin-bottom: 10px !important; green: #28a745 */
    width: 100% !important;
}
 
 .badges-info-header{
     display: inline;
 }

.grid-container{
    height: 600px;
    overflow-y: scroll;
}
.grid-container-single-Item{
    height: 400px;
   
}

.badge-details-container{
    padding: 1em;
}
.badge-details-container h2{
    padding: 0.7rem;
}
.badge-details-header h1 {
    font-size: 4rem;
 
}

.badge-details-content-partner{
left: auto;
right: auto;
}
 
.badge-details-content{
    font-size: 1.2rem;
}
.badge-details-footer{
margin-top: 16px;
}
 .ui.table thead tr:first-child > th {
    
    position: sticky !important;
    top: 0;
    z-index: 2;
   
}
/* 
.badge-table-header{

    border-style: dashed !important;
    border-width: 30px !important;
    border-top: hotpink !important;
} */

 


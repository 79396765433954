.rep-grid-container {
    display: grid;
    grid-template-columns: 168px auto auto auto;
 
 

  }
  .rep-grid-item {
 
    
    text-align: center;
  }
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}
.visit-background{

  position:fixed;
  top:30; 
  left:0;
  width:100%;
  height:100%;  
  overflow-y: auto;
  /* background-image:url('../assets/visIT_large_logo.png'); */
  background-repeat: no-repeat;
}
.report-segment {
  overflow-x: scroll;

  border: hidden !important;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.addDetailsHeader{
  margin-bottom: 7px ;
}





.formInput input {
  font-size: 19px !important;
  border-width: 1.5px !important;
}

.vReasonClass {
  font-size: 19px !important;
  border-width: 1.5px !important;
}

.formInput Button {
  font-size: 19px !important;
}

.highlightInputBorder {
  border-color: red !important;
}
.report-data-container{
  margin-top: 25px ;
  height: 20em;
  overflow-y: scroll;
 
}
.searchBtn {
  margin-top: 15.5px !important;
}

.welcome {
  margin-top: 250px;
  text-align: center;
  font-size: 80px;
  font-style: italic;
  color: grey;
}

.keyboardPosition {
  bottom: 20px;
  position: fixed;
  font-size: 1.8em !important;
  position: fixed;
  width: 70%;
  margin-left: 15% !important;
  font-weight: bolder;
  background-color: #f3f3f3;
}

.checkoutMessageStyle h1 {
  font-size: 2.9em;
  color: #000 !important;
  padding: 15px;
}

.checkoutMessageStyle {
  height: 400px !important;
  width: 400px !important;
  left: auto !important;
  right: auto !important;
  margin-top: 40px !important;
}

.checkoutMessageStyle p {
  font-size: 1.9em;
  color: red !important;
  padding: 15px;
}

.scrollDownContainer {
  overflow-y: scroll;
  height: 1050px !important;
  height: 100%;
}

/* .optionBox{
    margin: auto;
    margin-top: 90px;
    width: 50%;
    padding: 10px;
  } */

.BottomNam {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

@-moz-document url-prefix() {
  .ui.form input:not([type]),
  .ui.form input[type="date"],
  .ui.form input[type="datetime-local"],
  .ui.form input[type="email"],
  .ui.form input[type="file"],
  .ui.form input[type="number"],
  .ui.form input[type="password"],
  .ui.form input[type="search"],
  .ui.form input[type="tel"],
  .ui.form input[type="text"],
  .ui.form input[type="time"],
  .ui.form input[type="url"] {
    margin: -4px !important;
  }
}

.calendar {
  border-color: #4e4f50;
  color: #4e4f50;
  border-radius: 5px;
  border-style: solid;
  width: 150px !important;
  padding: 10px !important;
  margin-left: 100 !important;
}

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;ke
  margin: 0;
}

  input[type=number] {
  -moz-appearance: textfield;
} */

.printPage h4 {
  margin-top: 10px !important;
}

@media print {
  @page {
    size: A4;
    page-break-after: avoid;
  }

  a[href]:after {
    content: none !important;
  }

  .printPage table {
    border-width: 0 !important;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }

  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.printPage table {
  width: 100%;
}

.checkInMessage {
  font-style: italic;
  font-size: 20px;
  text-align: justify;
  color: red;
}

.modal-footer {
  flex: auto;
  flex-direction: row;
}

.modal-footer .action-text {
  color: #dd1710;
  margin-right: auto;
  margin-left: auto;
  font-size: 30px;
}

.house-rules-modal {
  height: 730px;
  left: auto;
  right: auto;
  margin-top: 80px !important ;
  font-size: 16px !important;
}
.health-safety-modal {
  height: 950px;
  left: auto;
  right: auto;
  margin-top: 80px !important;
  font-size: 15px !important;
}

.partner-identity-modal{
  height: 190px !important ;
  left: auto;
  right: auto;
  margin-top: 180px !important ;
  font-size: 16px !important;
}

.partner-house-rules-modal{
  height: 634px ;
  left: auto;
  right: auto;
  margin-top: 80px !important ;
  font-size: 16px !important;
}

.partner-clock-in-modal{
 height: 240px !important;
  width: 600px;
  left: auto;
  right: auto;
  margin-top: 180px !important ;
  font-size: 16px !important;
}

.modal-action{
  width: 92%;
  margin-left: 4%;
 }
 
.partner-btn{
  background-color: #1675a9 !important;
  color: #f3f3f3 !important;
  font-size: 30px !important;
}
.checkout-btn{
  margin-top: 50px !important;
  background-color: #eba900 !important;
  color: #f3f3f3 !important;
  font-size: 30px !important;
}

.regular-visitor-btn{
  margin-top: 50px !important;
  background-color: #2a9942!important;
  color: #f3f3f3 !important;
  font-size: 30px !important;
  width: 350px !important;
  height: 200px!important;
  border-radius: 20px !important;
}
.unplanned-visit-btn{
  margin-top: 50px !important;
  background-color: #1675a9!important;
  color: #f3f3f3 !important;
  font-size: 30px !important;
  width: 350px !important;
  height: 200px!important;
  border-radius: 20px !important;
}
.planned-visit-btn{
  margin-top: 50px !important;
  background-color: #eba900!important;
  color: #f3f3f3 !important;
  font-size: 30px !important;
  width: 350px !important;
  height: 200px!important;
  border-radius: 20px !important;
}


.code-option-modal{
  height: 150px;
   left: auto;
   right: auto;
   margin-top: 24% !important;
}


.booked-visits-tbl{
  height: 390px;
  overflow-y: scroll;
}

.booked-visits-grid-column{
  height: 500px;
 
}